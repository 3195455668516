<template>
  <div class="dashboard__container--body">
    <Loader v-if="!myGroups || myGroups.length == 0" />
    <div class="dashboard__container--body--col flex" v-for="(group, index) in myGroups" :key="group.id">
      <GroupCard :group="group" :approved="isApproved" :showLink="true" :truncated="true" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import GroupCard from '@/components/GroupCard.vue'

export default {
  name: 'myGroups',
  computed: {
    ...mapState(['userProfile', 'currentUser', 'groups']),
    // myGroups() {
    //   return this.groups.filter(group => {
    //     return group.members.includes(this.userProfile.id)
    //   })
    // }
    isApproved() {
      return true
    },
    myGroups() {
      return this.groups.filter(group => {
        return ((group.members && group.members.length >= 1) && group.members.includes(this.userProfile.id))
      })
    }
  },
  components: {
    Loader,
    GroupCard
  },
}
</script>