<template>
	<div class="groupCard pa-4 pb-1" v-if="group" style="background: white; width:100%;">
    <span class="flex justify-space-between">
    <h3 class="mb-2">{{group.title}}</h3>
    <router-link :to="`/jobs/apply/` + group.id" v-if="showLink">
      <i class="fa-solid fa-2x fa-arrow-up-right" style="color:#333333;"></i>
    </router-link>
    
    </span>
    <h5 class="mb-3"><i class="fa-regular fa-location-dot mr-2"></i> {{group.location}}</h5>
    
    <span v-if="group.startingPay">
      <hr>
      <div class="mt-3 mb-3 strong">Starting Pay:</div>
      <button class="btn__small chip mr-2 mb-2">${{group.startingPay}}/hr</button>
    </span>

    

    <span v-if="group.type && group.type.length >= 1">
      <hr>
      <div class="mt-3 strong">Job Type:</div>
      <div class="mt-3 mb-3"><button class="btn__small darkChip mr-2 mb-2" v-for="(item, index) in group.type">{{item}}</button></div>
    </span>

    <span v-if="group.job && group.job.length >= 1">
      <hr>
      <div class="mt-3 strong">Positions:</div>
      <div class="mt-3 mb-3"><button class="btn__small chip mr-2 mb-2" v-for="(item, index) in group.job">{{item.title}}</button></div>
    </span>
    <span v-if="group.description">
      <hr>
      <div class="mt-3 strong">Job Description:</div>
      <div class="mb-3 mt-3" v-html="group.description" v-if="truncated == false"></div>
      <div class="mb-3 mt-3" v-html="$options.filters.truncate(group.description, 240)" v-if="truncated == true"></div>
    </span>
    <hr>
    <div class="mb-3 mt-4" v-if="!approved">
      <router-link :to="{name: 'accountDetails'}" v-if="userProfile && userProfile.id">
        <button class="btn btn__primary btn__large mb-3">
          Verify Your Phone to Apply
        </button>
      </router-link>
      <!-- <router-link :to="{name: 'signupNew'}" v-if="!userProfile || !userProfile.id">
        <button class="btn btn__primary btn__large mb-3">
          Apply Now
        </button>
      </router-link> -->
      <a target="_blank" :href="`https://jumpstaffing.com/groupform/` + group.id" v-if="!userProfile || !userProfile.id">
        <button class="btn btn__primary btn__large mb-3">
          Apply Now
        </button>
      </a>
    </div>
    <div class="mb-3 mt-4" v-if="approved">

      <button class="btn btn__primary btn__large mb-3" @click="apply()" v-if="!isApplied(group) && !isWaitlisted(group)  && !isMember(group)">
      Apply Now
        <transition name="fade">
          <span class="ml-2" v-if="performingRequest">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
      </button>

      <div v-if="isMember(group)">
        <router-link :to="`/jobs/myjobs/` + group.id">
          <button class="btn btn__large btn__primary mr-3 mb-3">View Shifts</button>
        </router-link>
        <button class="btn btn__large btn__error" @click="leave()">
          Leave Job
          <transition name="fade">
            <span class="ml-2" v-if="performingRequest">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button>
      </div>


      <div v-if="isApplied(group)">
	      <button class="btn btn__large btn__outlined mr-3 mb-3">Application Pending</button>
				<button class="btn btn__large btn__error" @click="cancel()">
					Cancel Request
					<transition name="fade">
	          <span class="ml-2" v-if="performingRequest">
	          <i class="fa fa-spinner fa-spin"></i>
	          </span>
	        </transition>
				</button>
			</div>
    
      <div v-if="isWaitlisted(group)">
	     <button class="btn btn__large btn__warning mr-3 mb-3">Waitlisted</button>
			 <button class="btn btn__large btn__error" @click="cancelW()">
  				Cancel Request
  				<transition name="fade">
            <span class="ml-2" v-if="performingRequest">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
  			</button>
  		</div>

    </div>
    <div class="mb-3 mt-0" v-if="group.requiresEmployees">
      <div class="caption">*Some of thes jobs require that you be hired as an employee of Jump Employee Services LLC.</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const fb = require('../firebaseConfig.js')

export default {
  props: ['group', 'approved', 'showLink', 'truncated'],
  data: () => ({
  	performingRequest: false,
  }),
  computed: {
  	 ...mapState(['userProfile', 'currentUser', 'groupApplications']),
  },
  created () {
    this.$store.dispatch("getGroupFromId", this.group.id);
  },
  filters: { 
    truncate: function(string, value) {
      return string.substring(0, value) + '...';
    }
  },
  methods: {

    isMember(group) {
      if (this.userProfile.groups && this.userProfile.groups.length >= 1 && this.userProfile.groups.includes(group.id)) {
        return true
      } else {
        return false
      }
    },
    isApplied(group) {
    	if (this.userProfile.groupApplications && this.userProfile.groupApplications.includes(group.id)) {
    		return true
      } else {
        return false
    	}
    },
    isWaitlisted(group) {
      if (this.userProfile.groupWaitlist && this.userProfile.groupWaitlist.includes(group.id)) {
    		return true
      } else {
        return false
    	}
    },
    cancelW() {
    	this.performingRequest = true
      this.$store.dispatch("cancelWaitApplicationToGroup", {
        group: {
          groupId: this.group.id,
        },
        user: this.userProfile
      });
      setTimeout(() => {
          this.performingRequest = false  
      }, 1500)
    },
    cancel() {
    	this.performingRequest = true
      this.$store.dispatch("cancelApplicationToGroup", {
        group: {
          groupId: this.group.id,
        },
        user: this.userProfile
      });
      setTimeout(() => {
          this.performingRequest = false  
      }, 1500)
    },
    leave() {
      this.performingRequest = true
      this.$store.dispatch("leaveGroup", {
        group: {
          groupId: this.group.id,
        },
        user: this.userProfile
      });
      setTimeout(() => {
        this.$store.dispatch("getGroups")
          this.performingRequest = false  
      }, 1500)
    },
    apply() {
      this.performingRequest = true
      this.$store.dispatch("applyToGroup", {
        group: {
          groupId: this.group.id,
        },
        user: this.userProfile
      });
      setTimeout(() => {
          this.performingRequest = false  
      }, 1500)
    },
    formatDate(q) {
      if(q) {
        return moment(q).format('dddd, MMMM Do YYYY')
      } else {
        return null
      }
    },
    // destroyed() {
    //   this.$store.dispatch("clearGroupState")
    // }
  },
}
</script>